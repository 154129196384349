// Contact Us Page
if (window.location.pathname === "/contact-us" || window.location.pathname === "/contact-us/") {

    var phoneFields = document.getElementsByClassName("phone");
    for (i = 0; i < phoneFields.length; i++){
        inputmask("(999) 999-9999").mask(phoneFields[i]);
    }

    $("#contactForm").validate({
        errorPlacement: function(error, element) {
            if (element.is(":radio") || element.is(":checkbox")) {
                error.addClass('invalid-feedback');
                error.appendTo(element.parent().parent());
            } else {
                error.addClass('invalid-feedback');
                error.insertAfter(element);
            }
        },
        errorElement: "div",
        errorClass: "is-invalid",
        rules: {
            fname: {
                required: true,
            },
            lname: {
                required: true,
            },
            phone: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            message: {
                required: true,
            }
        }
    });

    $('#contactForm').submit(function(e) {
        e.preventDefault();

        if (isFormValid()) {
            // trigger ajax call to send mail
            sendContactMail();
        }
    });

    function isFormValid() {
        // returns a true or false
        return $("#contactForm").valid();
    }

    function sendContactMail() {
        var postData = $("#contactForm").serialize();
        var postUrl = $("#contactForm").attr("action");

        $.post(postUrl, postData, function(response) {
            if (response.success) {
                // display contact success message & clear form inputs
                $('#mailSent').fadeIn("slow");
                $('input[type="text"],textarea').val('');
            }
        }, "json");
    }
}