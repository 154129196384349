// Page: Loan Application
// --
import LoanApi from "../services/api/Loans";

// If page = /loan-application
if (
  window.location.pathname === "/loan-agreement" ||
  window.location.pathname === "/loan-agreement/"
) {

  $(function () {
    let customerId = $('#customer_id').val();
    let loanId = $('#loan_id').val();
    let agreementType = "Html";
    let forSigning = "true";

    LoanApi.getLoanAgreement(customerId, loanId, agreementType, forSigning)
      .then((response) => {
        if (response.success && response.data.html != undefined) {
          var parser = new DOMParser();
          var doc = parser.parseFromString(response.data.html, "text/html");
          let html = replaceAll(doc.body.innerText, 'jQuery', '$'); // scope the jQuery code to use $ instead of jQuery
          $('#hidden_agreementSequenceItem').val(response.data.agreement_sequence_item_key);
          $('#loanAgreementHtmlWpr').html(html);
          $('#loanAgreementForm').show();
        } else {
          $('#loanAgreementHtmlWpr').html("<p class='text-center pb-5'>We were unable to pull your Loan Agreement.</p>");
          $('#loanAgreementForm').show();
        }

        $('#eSignOverlay').hide(); // hide loading overlay
      });
  });

  $('#loanAgreementForm').submit(function (e) {
    $('#agreement_error_msg').hide();

    var signatureDataJson = PlmAgreement.getSignatureDataJson();
    if (signatureDataJson === '') {
      $('#agreement_error_msg').show();
      return false;
    }
    $("#hidden_signatureDataJson").val(signatureDataJson);
    return true;
  });

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

}