var placeSearch, autocomplete;

var componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  sublocality_level_1: 'short_name',
  postal_code: 'short_name'
};

window.initAutocomplete = function () {
  // set autcomplete fields attr to prevent chrome autofil from displaying overtop our autocomplete options
  setIgnoreAutocomplete();

  // Create the autocomplete object, restricting the search predictions to
  // geographical location types.
  autocomplete = new google.maps.places.Autocomplete(
    document.getElementById('street1'), {
      types: ['geocode'],
      componentRestrictions: {
        country: 'US'
      }
    }
  );

  // Avoid paying for data that you don't need by restricting the set of
  // place fields that are returned to just the address components.
  autocomplete.setFields(['address_component']);

  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener('place_changed', fillInAddress);
}

window.fillInAddress = function () {
  $('#restrictedStateErrorMsg').hide();

  // Get the place details from the autocomplete object.
  var place = autocomplete.getPlace();

  // remove current values
  document.getElementById('street1').value = '';
  document.getElementById('street2').value = '';
  document.getElementById('city').value = '';
  document.getElementById('state').value = '';
  document.getElementById('zip').value = '';

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  var placeObj = {};
  for (var i = 0; i < place.address_components.length; i++) {
    var addressType = place.address_components[i].types[0];
    if (componentForm[addressType]) {
      placeObj[addressType] = place.address_components[i][componentForm[addressType]] == 'undefined' ? "" : place.address_components[i][componentForm[addressType]];
    }
  }

  restrictedStates = ['AK', 'AZ', 'AR', 'CT', 'FL', 'HI', 'MD', 'MN', 'NJ', 'NY', 'PA', 'VT', 'VA', 'WV'];
  var state = placeObj['administrative_area_level_1'];
  if (restrictedStates.indexOf(state) !== -1) {
    // Show Error Message
    $('#restrictedStateErrorMsg').show();

    // Exit function
    return;
  }

  // Set the fields
  document.getElementById('street1').value = (placeObj['street_number'] || "") + " " + (placeObj['route'] || "");
  document.getElementById('city').value = (placeObj['locality'] || placeObj['sublocality_level_1'] || "");
  document.getElementById('state').value = (placeObj['administrative_area_level_1'] || "");
  document.getElementById('zip').value = (placeObj['postal_code'] || "");

  // so that our jquery validate updates
  $('#street1, #city, #state, #zip').valid();

}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
window.geolocate = function () {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      var circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}


// Disable auto complete on the street1 input, as this covers our options
window.setIgnoreAutocomplete = function () {
  var autocompleteInput = document.getElementById("street1");

  var observerHack = new MutationObserver(function () {
    observerHack.disconnect();
    $("#street1").attr("autocomplete", "new-password");
  });

  observerHack.observe(autocompleteInput, {
    attributes: true,
    attributeFilter: ['autocomplete']
  });
}