import jsPDF from "jspdf";

export default {

  html2Pdf(html, filename) {
    var doc = new jsPDF();

    var specialElementHandlers = {
      "#ignoreContent": function (element, renderer) {
        return true;
      }
    };

    doc.fromHTML(html, 15, 15, {
      width: 170,
      elementHandlers: specialElementHandlers
    });

    doc.save(filename);
  },

  printHtml(html) {
    var mywindow = window.open("", "PRINT", 'width="4in",height="8in"');
    mywindow.document.write("<html><body>");
    mywindow.document.write("<style>@page { size: A4 landscape; margin: 10%; }</style>");
    mywindow.document.write(html);
    mywindow.document.write("</body></html>");
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();

    return true;
  }

}