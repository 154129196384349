export default {

    // Loan Details are pulled from the authenticated user inside the "API" ( not a true api, it's more like JSON endpoints )

    getLoan(loanId) {
        return fetch(`/api/infinity/getLoan?loanId=${loanId}`)
          .then((response) => {
            return response.json().then((data) => {
              return data;
            }).catch((err) => {
              console.log(err);
            })
          });
      },

      getPaymentSchedule(loanId) {
        return fetch(`/api/infinity/getLoanSchedule?loanId=${loanId}`)
          .then((response) => {
            return response.json().then((data) => {
              return data;
            }).catch((err) => {
              console.log(err);
            })
          });
      },

      getLoanPayments(loanId) {
        return fetch(`/api/infinity/getLoanPayments?loanId=${loanId}`)
          .then((response) => {
            return response.json().then((data) => {
              return data;
            }).catch((err) => {
              console.log(err);
            })
          });
      },

      getLoanBalance(loanId) {
        return fetch(`/api/infinity/getLoanBalance?loanId=${loanId}`)
          .then((response) => {
            return response.json().then((data) => {
              return data;
            }).catch((err) => {
              console.log(err);
            })
          });
      },

      getLoanAgreement(customerId, loanId, type = "html", forSigning = "true") {
        return fetch(`/api/infinity/getLoanAgreement?customerId=${customerId}&loanId=${loanId}&type=${type}&forSigning=${forSigning}`)
          .then((response) => {
            return response.json().then((data) => {
              return data;
            }).catch((err) => {
              console.log(err);
            })
          });
      }

}