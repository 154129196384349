// Contact Us Page
if (window.location.pathname === "/opt-out" || window.location.pathname === "/opt-out/") {

    var phoneFields = document.getElementsByClassName("phone");
    for (i = 0; i < phoneFields.length; i++){
        inputmask("(999) 999-9999").mask(phoneFields[i]);
    }

    $("#optOutForm").validate({
        errorPlacement: function(error, element) {
            if (element.is(":radio") || element.is(":checkbox")) {
                error.addClass('invalid-feedback');
                error.appendTo(element.parent().parent());
            } else {
                error.addClass('invalid-feedback');
                error.insertAfter(element);
            }
        },
        errorElement: "div",
        errorClass: "is-invalid",
        rules: {
            fname: {
                required: true,
            },
            lname: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            'optOutOption[]': {
                required: true,
            }
        },
        messages: {
            'optOutOption[]': {
                required: "You must check at least 1 box",
            }
        }
    });

    $('#optOutForm').submit(function(e) {
        e.preventDefault();

        if (isFormValid()) {
            // trigger ajax call to send mail
            sendOptOutMail();
        }
    });

    function isFormValid() {
        // returns a true or false
        return $("#optOutForm").valid();
    }

    function sendOptOutMail() {
        var postData = $("#optOutForm").serialize();
        var postUrl = $("#optOutForm").attr("action");

        $.post(postUrl, postData, function(response) {
            if (response.success) {
                // display contact success message & clear form inputs
                $('#mailSent').fadeIn("slow");
                $('input[type="text"],textarea').val('');
                $("#businessCredit").prop("checked", false);
                $("#marketYou").prop("checked", false);
            }
        }, "json");
    }
}