Object.defineProperty(String.prototype, "kebabCase", {
  value: function () {
    return this.replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, "-")
      .toLowerCase();
  },
});

Object.defineProperty(String.prototype, "moneyFormat", {
  value: function () {
    return this.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  },
});

Object.defineProperty(String.prototype, "replaceAll", {
  value: function (find, replace) {
    return this.replace(new RegExp(find, 'g'), replace);
  },
});